@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap");

body {
  background: #ffffff;
}

.appbar::-webkit-scrollbar {
  width: 6px;
}

.appbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.appbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.appbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.MuiInputLabel-root {
  /* font-family: "Nunito" !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  /* font-size: 20px !important; */
  font-size: 20px;
  line-height: 27px !important;
  /* color: #92959a !important; */
}

.MuiTextField-root {
  box-sizing: border-box !important;
  background: #ffffff;
  /* border-bottom: 2px solid !important; */
  border-radius: 2px !important;
}

.light-Textfield {
  /* border-bottom: 0.5px solid #939eaa !important;
  border-radius: 2px !important; */
}

.dark-Textfield {
  /* border-bottom: 2px solid #000000 !important;
  border-radius: 2px !important; */
}

.satndard-tEXT .dark-Textfield:disabled {
  border-bottom: 2px solid #92959a !important;
}

.MuiButton-outlined {
  height: 60px !important;
  padding: 18px 27px !important;
  background: #ffffff !important;
  border: 1px solid #92959a !important;
  border-radius: 5px !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
}

.MuiButton-contained {
  height: 60px !important;
  padding: 18px 27px !important;
  gap: 7px !important;
  background: #46448d !important;
  /* opacity: 0.5 !important; */
  border: 1px solid #46448d !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px !important;
}

.MuiSelect-select {
  /* background: #ffffff !important;
  border: 0.5px solid #92959a !important;
  border-radius: 2px !important; */
}

.MuiSelect-standard {
  min-width: 100px !important;
  border: none !important;
  border-radius: none !important;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-cell {
  border: none !important;
  border-left: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
}

.MuiDataGrid-columnHeader {
  background: #798593 !important;
  color: #ffffff !important;
  border: none !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px 5px 0px 0px !important;
}

.MuiToolbar-regular {
  height: 78px !important;
  color: black;
  background: #ffffff !important;
  border: 0.1px solid #abb2bb !important;
  border-left: none !important;
}

.MuiAppBar-root {
  box-shadow: none !important;
}

.MuiListItem-root {
  padding: 25px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 27px !important;
}

.MuiListItem-root:hover {
  background-color: #46448d;
  color: white;
  border-right: 6px solid orange;
}

.sidebar-head {
  padding: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.breadcrumb-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  color: #abb2bb;
}

.nav-icon {
  padding: 15px;
}

.lead-list {
  display: flex;
  align-items: center !important;
  color: #795718 !important;
}

.MuiListItemText-primary {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
}

.lead-list:hover {
  background: #edecf4 !important;
  border-width: 0.2px 0px 0.2px 0.2px !important;
  border-style: solid !important;
  border-color: #abb2bb !important;
  color: #00a3ff !important;
}

.container {
  background: transparent !important;
  /* height: 100% !important; */
  /* overflow-y: scroll; */
}

.card-container {
  height: 100% !important;
}

.containerHead {
  height: 90px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 50px;
  background: #46448d;
  border-radius: 10px 10px 0px 0px;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
}

.containerContent {
  padding: 30px;
}

.unverifiedlead-tab {
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #46448d;
}

.unverifiedlead-tab-clicked {
  background: #46448d;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 76px;
  color: #ffffff;
}

.editLeadStepper {
  display: flex;
  align-items: center;
  padding: 45px;
  height: 45px;
  background: rgba(205, 175, 121, 0.19);
}

.MuiStepIcon-root {
  color: white !important;
  fill: #ffffff !important;
}

/* .MuiStepIcon-root {
  background-color: #ffffff;
  color: white !important;
  
} */

.MuiStepIcon-text {
  fill: #92959a !important;
  /* fill:white !important; */
}

.Mui-completed {
  /* color: #00BD79 !important; */

  fill: #00bd79 !important;
}

.Mui-active {
  fill: #00bd79 !important;
}

/* .css-117w1su-MuiStepIcon-text {
  fill: #92959A  !important;
  font-size: 0.75rem;
} */

.dashboard-card {
  height: 185px;
  box-shadow: 3px 6px 21px -5px rgba(0, 0, 0, 0.25) !important;
  background: #ffffff;
  border-radius: 10px !important;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-card2 {
  height: 125px;
  box-shadow: 4px 7px 21px -5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}

.dashboard-card2-content {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.dashboard-side-content {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.dashboard-card-light-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #abb2bb;
  text-align: center;
}

.dashboard-card-dark-text {
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #000000;
  text-align: center;
}

.user-badge {
  border: 2px solid #fab140;
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 100%;
}

.dashboard-task-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #abb2bb;
}

.dashboard-task-tag {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #14af12;
  margin-right: 11px;
}

.e-calendar {
  height: 375px !important;
  border: none !important;
  width: 100% !important;
  background: none !important;

  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important; */
}

.e-header .e-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #fab140 !important;
  border: 1px solid #fab140 !important;
  color: white;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #fab140;
  border: none;
  border-radius: 50%;
  color: #fff;
}

.MuiDialog-paperWidthSm {
  border-radius: 30px !important;
}

.get-lead-card {
  padding: 39px;
  box-shadow: 6px 4px 32px -6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.login-input {
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 10px 8px 64px -26px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.input-seprator {
  margin-left: 21px;
  height: 24px;
  opacity: 0.5;
  border: 1px solid #abb2bb;
}

.button-curved {
  color: white !important;
  font-weight: 600 !important;
  font-size: 28px !important;
  line-height: 33px !important;
  background: #46448d !important;
  border-radius: 60px !important;
  height: 95px !important;
}

.socialmedia-icon {
  margin: 10px;
  opacity: 0.5;
  border: 1px solid #46448d;
  width: 131px;
  height: 131px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-button {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  background: #ffffff;
  border: 0.5px solid #46448d;
  border-radius: 60px;
  height: 38px;
}

.signup-addphoto-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 236px;
  height: 236px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 2px 0px 76px -24px rgba(0, 0, 0, 0.25);
}

.task-head-icon {
  width: 44px;
  height: 44px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

/* ------------------------------------------------------------------------------------------------------ */

/* .rbc-toolbar {
  display: none !important;
} */

.rbc-header {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #000000 !important;
  padding: 10px !important;
  background: rgba(70, 68, 141, 0.1) !important;
}

.rbc-button-link {
  font-weight: 600 !important;
  font-size: 28px !important;
  line-height: 38px !important;
}

.rbc-off-range {
  /* color: #999999; */
  color: white !important;
}

.rbc-off-range-bg {
  background: none !important;
}

.calendar-taskname {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  color: #000000;
  height: 36px;
  margin-top: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px;
}

.calendar-scheduleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;

  background: #00a3ff;
}

.formAlert-heading {
  width: 100%;
  height: 63px;
  background: #46448d;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;

  color: #ffffff;
}

.formAlert-content {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
}

/* Funnel Page UI CSS */

.WhiteBgInput {
  border: none !important;
}

.WhiteBgInput:hover {
  border: none !important;
}

.WhiteBgInput:focus {
  border: none !important;
}

.WhiteBgInput::after {
  border: none !important;
}

.WhiteBgInput::before {
  border: none !important;
}

.blueBgContainer {
  clip-path: polygon(0 0, 100% 0, 140% 80%, 0 100%);
  padding: 2.5em 4em 10em 4em;
}

.funnelFontFamily {
  font-family: "Montserrat", sans-serif !important;
}

.withBorder {
  border: 1px solid gray !important;
}

.input-size {
  width: 75%;
}

@media screen and (max-width: 400px) {
  .blueBgContainer {
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%) !important;
    padding: 1.5em 1.5em 3em 1.5em !important;
    font-family: "Montserrat", sans-serif !important;
  }
}

@media screen and (max-width: 600px) {
  .blueBgContainer {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%) !important;
    padding: 2em 2em 4em 2em !important;
  }
}

@media screen and (max-width: 900px) {
  .blueBgContainer {
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%) !important;
    padding: 2.5em 2.5em 5em 2.5em !important;
  }

  .input-size {
    width: 100%;
  }

  .hero__padding {
    padding-top: 20px;
    /* padding-bottom: 100px; */
  }
}

.react-datalist-input__textbox {
  width: 100%;
  height: 56px !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  padding: var(--padding);
  border: var(--border);
  border-radius: var(--border-radius);
}

.pipeline-conatiner {
  padding: 15px;
}

.pipeline-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;

  background: #798593;
  border-radius: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;

  color: #ffffff;
}

.pipeline-content {
  width: 100%;
  height: 130px;
  left: 620px;
  top: 121px;

  background: #ffffff;
  border: 1px solid #798593;
  border-radius: 10px;
}

/* funnel html css */
.funnels-card {
  /* padding:5px; */
  height: 360px;
  background: #ffffff;
  box-shadow: 3px 4px 24px -5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  /* display: flex; */
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.funnels-img {
  height: 270px;
  position: relative;
  border-radius: 10px 10px 0px 0px;
}

.funnels-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.funnels-icons-content {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  padding: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.funnels-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  color: #000000;
}

.funnel-head2 {
  background-image: url("../public/assests/crmfunnel/Funnel1/2.jpg");
  background-size: cover;
  height: 100vh;
}

.funnel-head {
  background-image: url("../public/assests/crmfunnel/Funnel1/4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.inclined-svg {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
}

.funnel-text-bar {
  /* position: fixed; */
  width: 100%;
  background: #46448d;
  border-radius: 10px;
  font-weight: 700;
  font-size: 38px;
  line-height: 129%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  bottom: 0;
}

.funnel-man-img {
  position: absolute;
  left: 5%;
  top: 178px;
  /* transform: translateY(-50%); */
}

.funnel-heading {
  position: relative;
  left: 5%;
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  /* justify-content: start;
  align-items: center; */
  align-items: center;
  font-weight: 700;
  line-height: 129%;
  text-align: center;
  letter-spacing: 0.075em;
  color: #ffffff;
}

.rec-blur {
  width: 56px;
  height: 94px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.47) 100%);
  backdrop-filter: blur(29.5px);
  border-radius: 5px;
}

@media only screen and (max-width: 991px) {
  .funnel-text-bar {
    font-size: 18px;
    /* margin-top: 40%; */
  }

  .funnel-text-bar-container {

    /* position: absolute; */
    /* bottom: 0; */
  }

  .headingText {
    height: 100%;
    margin-top: 20%;
  }

  .headingFunnel {
    font-size: 32px !important;
  }

  .funnel-form {
    margin: 15px;
  }

  .funnel-button {
    padding: 10px !important;
    height: auto !important;
    font-size: 12px !important;
    color: white !important;
  }

  .funnel-form-title {
    font-size: 18px !important;
  }

  .funnel-head {
    height: auto;
  }
}

.headingText {
  height: 70% !important;
}

.funnel-form {
  /* width: 80%; */
  /* margin-top: 35px; */
  align-self: center;
  justify-content: center;
  padding: 35px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.21) 0%,
      rgba(255, 255, 255, 0.22) 100%);
  backdrop-filter: blur(32.5px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.funnel-form-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 129%;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 2px 13px rgba(0, 0, 0, 0.34);
}

.form-divider {
  width: 50%;
  height: 8px;
  margin-top: 10px;
  background-image: linear-gradient(90.01deg, #fab140 -0.03%, #ffffff 100.05%);
}

.funnel-button {
  background: linear-gradient(92.79deg, #ff5b2e 6.31%, #fab140 95.19%);
  box-shadow: 3px 4px 13px 4px rgba(0, 0, 0, 0.25);
  border-radius: 60px;
  width: 100%;
  padding: 10px !important;
  font-weight: 700;
  font-size: 26px;
  border-radius: 60px !important;
  color: white !important;
}

.img1 {
  height: 635px;
  border-radius: 25px;
}

.benefits-section {
  padding: 35px;
  height: 100%;
}

.benefits-divider {
  width: 30%;
  height: 10px;
  background-image: linear-gradient(90deg, #ff5b2e -1.32%, #fab140 98.7%);
}

.vl {
  border-left: 2px solid #000000;
  height: 100px;
  margin-top: -10%;
}

.stepper-label {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 26px;
  line-height: 157.5%;
  color: #000000;
  margin-top: -7%;
}

.funnel-footer-section {
  width: 100vw;
  background-image: url("../public/assests/crmfunnel/Funnel1/1.jpg");
  background-size: cover;
  height: 730px;
  position: relative;
}

.footer-svg {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  /* transform: translateY(-100%); */
}

.funnel-man-img2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.download-button {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert-external {
  background-color: #746fbf;
  font-size: 16px;
  text-align: center;
  padding: 5px;
  margin: 10px;
  cursor: pointer;
}

.external-name {
  color: #ffffff;
  text-decoration: none;
  padding: 5px;
}

.alert-external:hover {
  background-color: #615ca8;
}

.overlays {
  position: relative;
  z-index: 1;
}

.overlays::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(6, 6, 6, 0.3);
}

.fields {
  background: #ffffff !important;
  /* border: 0.5px solid #92959a !important; */
  border-radius: 2px !important;
  box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.25);
}

.validation-error {
  color: red;
}

.cursor {
  cursor: pointer;
}